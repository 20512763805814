import encryptor from "./Encryptor";

export const STORAGE = {
  ACCESS_TOKEN: "@accessToken",
  REFRESH_TOKEN: "@refreshToken",
  USER_INFO: "@userInfo",
  COLOR_DATA: "@colorData",
  IS_CHANGED: "@isChanged",
  EMAIL_INFO: "@emailInfo",
  FLOW_APPROVE_INFO: "@flowApproveInfo",
  USER_ROLE: "@userRole",
  PREVIEW_DATA: "@previewData",
  INVALID: "@invalid",
  CSV_INFO: "@csvInfo",
  LOCATION_CODE: "@locationCode",
  ALL_REPORT_ITEM: "@allReportItem",
  MAX_PRODUCT_ID: "@maxProductId",
  MAX_IMAGE_RISKY_REPORT: "@maxImageRiskyReport",
  BARCODE_LAYOUT: "@2d_barcode",
  CONTRACT_INFO: "@contractInfo",
  ALL_USER_MASTER_ITEM: "@allUserMasterItem",
};

export const storeData = (keyField: string, data: string) => {
  localStorage.setItem(keyField, encryptor.Encrypt(data));
};

export const clearData = (keyField: string) => {
  localStorage.removeItem(keyField);
};

export const getDataStorage = (keyField: string, defaultValue: string = "") => {
  try {
    const item: any = localStorage.getItem(keyField);
    if (item === null) return defaultValue;

    const value: any = encryptor.Decrypt(item);
    return value ?? defaultValue;
  } catch (e) {
    console.log("e", e);
  }
};
