import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { Circle, Info } from "@mui/icons-material";
import LabelRequired from "components/atoms/LabelRequired";
import SelectLabel from "components/atoms/SelectLabel";
import {
  CUSTOM_FORMAT,
  EXCEL_DATE_FORMAT,
  EXCEL_ROUND,
  EXCEL_ROUND_PLACE,
  EXCEL_TEMPLATE_FONT_COLOR,
  EXCEL_TEMPLATE_INPUT,
  EXCEL_TIME_FORMAT,
} from "@shared-constants";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { TemplateExcelDataInfo, TemplateExcelRadio } from "services/models";
import { Validation } from "@validation";
import _ from "lodash";
import messages from "config/messages";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getMasterType } from "@api/masterType";
import Dropzone from "components/atoms/Dropzone";
import { createObjectURL } from "@utils/index";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import { Colors } from "@template/style";
import DateFormatInfoDialog from "./DateFormatInfoDialog";
import GroupInputDialog from "./GroupInputDialog";

interface IProps {
  data?: TemplateExcelDataInfo;
  excelTemplate?: Array<TemplateExcelDataInfo>;
  isLandscape?: boolean;
  listInputName?: Array<string>;
  activityBaseId: string;
  inputMenuDiv?: TInputMenuDiv;
}

interface IListSelectedMaster {
  coordinate: number; // 1000から連番
  custom_item_name: string; // 紐づけ元項目名 + " - " + マスタ追加項目の項目名,
  master_coordinate: string; // 紐づけ元マスタの座標
  master_attr: string; // マスタ追加項目のattribute
}

const initial_form_error = {
  name: "",
  maxlen: "",
  lineRows: "",
  fontSize: "",
  image: "",
  calc: "",
  customizeFormat: "",
  displayStep: "",
  category_name: "",
  button_name: "",
  maxFileSize: "",
  separator: "",
};

const ListImagePageSelectable: Array<number> = [
  EXCEL_TEMPLATE_INPUT.TEXT,
  EXCEL_TEMPLATE_INPUT.MASTER,
  EXCEL_TEMPLATE_INPUT.DATE,
  EXCEL_TEMPLATE_INPUT.REPORT_NO,
  EXCEL_TEMPLATE_INPUT.USER_NAME,
  EXCEL_TEMPLATE_INPUT.RADIO,
  EXCEL_TEMPLATE_INPUT.TIME,
  EXCEL_TEMPLATE_INPUT.BARCODE,
  EXCEL_TEMPLATE_INPUT.CHECKBOX,
];

const ListSealSelectable: Array<number> = [EXCEL_TEMPLATE_INPUT.USER_NAME];

type TInputMenuDiv = "all" | "groupInput";

const CreateTemplateInput = (
  {
    data,
    excelTemplate = [],
    isLandscape,
    listInputName = [],
    activityBaseId,
    inputMenuDiv = "all",
  }: IProps,
  ref: any,
) => {
  const [stateForm, setStateForm] = useState<TemplateExcelDataInfo>();
  const [originData, setOriginData] = useState<TemplateExcelDataInfo>();
  const [formError, setFormError] = useState(initial_form_error);
  const [listMaster, setListMaster] = useState<any[]>([]);
  const [openDateFormatInfo, setOpenDateFormatInfo] = useState<boolean>(false);
  const [openGroupInput, setOpenGroupInput] = useState<boolean>(false);

  useEffect(() => {
    setStateForm(data);
    setOriginData(data);
    setFormError(initial_form_error);
  }, [data]);

  useEffect(() => {
    const getListMaster = async () => {
      LoadingOverlayController.show();
      await getMasterType(activityBaseId)
        .then((res) => {
          if (res?.data) {
            setListMaster(res.data);
          }
        })
        .finally(() => LoadingOverlayController.hide());
    };
    getListMaster();
  }, [activityBaseId]);

  useImperativeHandle(ref, () => ({
    stateForm: stateForm,
    hasUpdateData: hasUpdateData,
    validationAllCheck: validationAllCheck,
  }));

  const hasUpdateData = () => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  };

  const validationAllCheck = () => {
    let mess_name = validate("name");
    let mess_maxlen =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.TEXT ||
      stateForm?.input == EXCEL_TEMPLATE_INPUT.NUMBER ||
      stateForm?.input == EXCEL_TEMPLATE_INPUT.INPUT_GROUP ||
      EXCEL_TEMPLATE_INPUT.ATTACHED_FILE
        ? validate("maxlen")
        : "";
    let mess_line_rows =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.TEXT ? validate("lineRows") : "";
    let mess_font_size = validate("fontSize");
    let mess_draw_image =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.DRAW_IMAGE
        ? validate("image")
        : "";
    let mess_calc =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.CALC ? validate("calc") : "";
    let mess_date_string_format =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.DATE ||
      stateForm?.input == EXCEL_TEMPLATE_INPUT.TIME
        ? validate("customizeFormat")
        : "";
    let mess_display_step = validate("displayStep");
    let mess_category_name =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.INPUT_GROUP
        ? validate("category_name")
        : "";
    let mess_button_name =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.INPUT_GROUP
        ? validate("button_name")
        : "";
    let mess_max_file_size =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.ATTACHED_FILE
        ? validate("maxFileSize")
        : "";
    let mess_separator =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.INPUT_GROUP
        ? validate("separator")
        : "";

    const form_error = {
      name: mess_name,
      maxlen: mess_maxlen,
      lineRows: mess_line_rows,
      fontSize: mess_font_size,
      image: mess_draw_image,
      calc: mess_calc,
      customizeFormat: mess_date_string_format,
      displayStep: mess_display_step,
      category_name: mess_category_name,
      button_name: mess_button_name,
      maxFileSize: mess_max_file_size,
      separator: mess_separator,
    };
    setFormError(form_error);

    return !Object.values(form_error).some((value) => value != "");
  };

  const validate = (
    field: string,
    message_name?: string,
    input_type?: number,
  ) => {
    if (!stateForm) return "";

    let message = "";
    switch (field) {
      case "name":
        message = Validation.validate({
          type: "text",
          value: stateForm[field],
          name: "項目名",
          required: true,
        });
        if (message === "") {
          const exist = excelTemplate.some(
            (item) =>
              item.coordinate != stateForm.coordinate &&
              item.name == stateForm.name,
          );
          if (exist) {
            message = messages.COMMON.ERROR.MSG_EXISTING("項目名");
          }
        }
        break;
      case "maxlen":
        let name = "上限桁数";
        if (stateForm.input === EXCEL_TEMPLATE_INPUT.INPUT_GROUP) {
          name = "上限数";
        }
        message = Validation.validate({
          type: "number",
          name: message_name ? message_name : "上限桁数",
          value: stateForm[field],
          required: false,
          min_value: 1,
          max_value:
            input_type === EXCEL_TEMPLATE_INPUT.NUMBER ? 15 : undefined,
        });
        break;
      case "lineRows":
        message = Validation.validate({
          type: "number",
          name: "行数",
          value: stateForm[field],
          required: true,
          min_value: 1,
        });
        break;
      case "fontSize":
        message = Validation.validate({
          type: "number",
          name: "フォントサイズ",
          value: stateForm[field],
          required: true,
          min_value: 1,
        });
        break;
      case "image":
        message = Validation.validate({
          type: "text",
          name: "画像",
          value: stateForm[field]?.uri,
          required: true,
        });
        break;
      case "calc":
        message = Validation.validate({
          type: "text",
          name: "計算式",
          value: stateForm[field],
          required: true,
        });
        break;
      case "customizeFormat":
        if (stateForm.dateFormat === CUSTOM_FORMAT) {
          // 表示形式がカスタマイズのとき
          message = Validation.validate({
            type: "text",
            name: "カスタマイズ形式",
            value: stateForm[field],
            required: true,
          });
        }
        break;
      case "displayStep":
        if (stateForm.required && stateForm.display_step) {
          const required_step = stateForm.required_step ?? 0;
          if (required_step < stateForm.display_step) {
            message = messages.TEMPLATE.MSG_TEMPLATE_EXCEL_DISP_STEP;
          }
        }
        break;
      case "category_name":
        message = Validation.validate({
          type: "text",
          name: "タブ名",
          value: stateForm[field],
          required: true,
        });
        break;
      case "button_name":
        message = Validation.validate({
          type: "text",
          name: "追加ボタン名",
          value: stateForm[field],
          required: true,
        });
        break;
      case "maxFileSize":
        message = Validation.validate({
          type: "number",
          name: "ファイル容量制限",
          value: stateForm[field],
          min_value: 1,
          max_value: 2048,
          required: true,
        });
        break;
      case "separator":
        message = Validation.validate({
          type: "text",
          name: "区切り文字",
          value: stateForm[field],
          required: true,
        });
    }
    setFormError({ ...formError, [field]: message });
    return message;
  };

  const _renderInput = useCallback(() => {
    if (!stateForm) return <></>;
    switch (stateForm.input) {
      case EXCEL_TEMPLATE_INPUT.TEXT:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            <TextField
              label="上限桁数"
              value={stateForm.maxlen}
              onChange={(e) => {
                setStateForm({ ...stateForm, maxlen: e.target.value });
              }}
              onBlur={() => validate("maxlen")}
              error={formError?.maxlen.length > 0}
              helperText={formError?.maxlen}
            />
            <Box>
              <CheckboxLabel
                label="改行あり"
                checked={stateForm.multiline}
                onChange={(_, checked) => {
                  setStateForm({
                    ...stateForm,
                    multiline: checked,
                    lineRows: checked ? stateForm.lineRows : 1,
                  });
                }}
              />
            </Box>
            <TextField
              label={<LabelRequired title="行数" />}
              value={stateForm.lineRows}
              onChange={(e) => {
                let v: any = e.target.value;
                if (v && !isNaN(Number(v))) {
                  v = Number(v);
                }
                setStateForm({ ...stateForm, lineRows: v });
              }}
              disabled={
                stateForm.input != EXCEL_TEMPLATE_INPUT.TEXT ||
                !stateForm.multiline
              }
              onBlur={() => validate("lineRows")}
              error={formError?.lineRows.length > 0}
              helperText={formError?.lineRows}
            />
            {DefaultInput}
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.MASTER:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            <SelectLabel
              label={<LabelRequired title="マスタ" />}
              value={stateForm.master}
              onChange={(e) => {
                setStateForm({ ...stateForm, master: e.target.value });
              }}
            >
              {listMaster.map((item) => (
                <MenuItem value={item.prefix} key={item.SK}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectLabel>
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.DATE:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
            <SelectLabel
              label={<LabelRequired title="表示形式" />}
              value={stateForm.dateFormat ?? EXCEL_DATE_FORMAT[0].value}
              onChange={(e) => {
                setStateForm({
                  ...stateForm,
                  dateFormat: e.target.value,
                  customizeFormat: "",
                });
                setFormError({ ...formError, customizeFormat: "" });
              }}
            >
              {EXCEL_DATE_FORMAT.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </SelectLabel>
            {CustomizeFormat(stateForm.dateFormat !== CUSTOM_FORMAT)}
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.TIME:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
            <SelectLabel
              label={<LabelRequired title="表示形式" />}
              value={stateForm.timeFormat ?? EXCEL_TIME_FORMAT[0].value}
              onChange={(e) => {
                setStateForm({
                  ...stateForm,
                  timeFormat: e.target.value,
                  customizeFormat: "",
                });
                setFormError({ ...formError, customizeFormat: "" });
              }}
            >
              {EXCEL_TIME_FORMAT.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </SelectLabel>
            {CustomizeFormat(stateForm.timeFormat !== CUSTOM_FORMAT)}
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.IMAGE:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {RequiredInput}
            {displayInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.IMAGE_PAGE:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            <TextField
              label="画像タイトル"
              value={stateForm.titleImage}
              onChange={(e) => {
                setStateForm({ ...stateForm, titleImage: e.target.value });
              }}
            />
            {ImagePageHeader("項目1", "headImage1")}
            {ImagePageHeader("項目2", "headImage2")}
            {ImagePageHeader("項目3", "headImage3")}
            {ImagePageHeader("項目4", "headImage4")}
            {isLandscape && (
              <>
                {ImagePageHeader("項目5", "headImage5")}
                {ImagePageHeader("項目6", "headImage6")}
              </>
            )}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.REPORT_NO:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.USER_NAME:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.USER_SEAL:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.APPROVED_SEAL:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {ApprovalStep}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.APPROVED_DATE:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {ApprovalStep}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.SIGNATURE:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {RequiredInput}
            {displayInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.DRAW_IMAGE:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            <FormControl>
              <CheckboxLabel
                label="お絵描きする"
                checked={stateForm.isDraw}
                onChange={(_, checked) => {
                  setStateForm({ ...stateForm, isDraw: checked });
                }}
              />
            </FormControl>
            {stateForm.image && stateForm.image.uri !== "" ? (
              <CancelBtnBadge
                onClick={() => {
                  setStateForm({ ...stateForm, image: undefined });
                }}
                sx={{ width: "100%" }}
                componentsProps={{ badge: { style: { right: "7%" } } }}
              >
                <Box
                  component="img"
                  src={stateForm.image?.uri}
                  sx={{
                    height: 140,
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: 1,
                  }}
                />
              </CancelBtnBadge>
            ) : (
              <Box width={"100%"}>
                <Dropzone
                  onChoose={(res) => {
                    const uri = createObjectURL(res[0]);
                    res[0]["uri"] = uri;
                    setFormError({ ...formError, image: "" });
                    setStateForm({ ...stateForm, image: res[0] });
                  }}
                  maxFiles={1}
                  showFileList={false}
                />
              </Box>
            )}
            <FormHelperText error>{formError?.image}</FormHelperText>
            {displayInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.CALC:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            <TextField
              label={<LabelRequired title="計算式" />}
              value={stateForm.calc}
              onChange={(e) => {
                setStateForm({ ...stateForm, calc: e.target.value });
              }}
              onBlur={() => validate("calc")}
              error={formError?.calc.length > 0}
              helperText={formError?.calc}
            />
            <SelectLabel
              label={<LabelRequired title="小数点以下" />}
              value={stateForm.calcRoundPlace ?? EXCEL_ROUND_PLACE[0].value}
              onChange={(e) => {
                setStateForm({
                  ...stateForm,
                  calcRoundPlace: Number(e.target.value),
                });
              }}
            >
              {EXCEL_ROUND_PLACE.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </SelectLabel>
            <SelectLabel
              label={<LabelRequired title="端数処理" />}
              value={stateForm.calcRound ?? EXCEL_ROUND[0].value}
              onChange={(e) => {
                setStateForm({ ...stateForm, calcRound: e.target.value });
              }}
            >
              {EXCEL_ROUND.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </SelectLabel>
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.NUMBER:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            <TextField
              label="上限桁数"
              value={stateForm.maxlen}
              onChange={(e) => {
                setStateForm({ ...stateForm, maxlen: e.target.value });
              }}
              onBlur={() =>
                validate("maxlen", undefined, EXCEL_TEMPLATE_INPUT.NUMBER)
              }
              error={formError?.maxlen.length > 0}
              helperText={formError?.maxlen}
            />
            {DefaultInput}
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.RADIO:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {RadioInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.BARCODE:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.CHECKBOX:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
            <TextField
              label="選択時表示内容"
              value={stateForm.checkbox?.checked_text}
              onChange={(e) => {
                setStateForm({
                  ...stateForm,
                  checkbox: {
                    checked_text: e.target.value,
                    unchecked_text: stateForm.checkbox?.unchecked_text,
                  },
                });
              }}
            />
            <TextField
              label="非選択時表示内容"
              value={stateForm.checkbox?.unchecked_text}
              onChange={(e) => {
                setStateForm({
                  ...stateForm,
                  checkbox: {
                    checked_text: stateForm.checkbox?.checked_text,
                    unchecked_text: e.target.value,
                  },
                });
              }}
            />
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.INPUT_GROUP:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            <Button onClick={() => setOpenGroupInput(true)}>
              入力項目設定
            </Button>
            <TextField
              label={<LabelRequired title="タブ名" />}
              value={stateForm.category_name}
              onChange={(e) => {
                setStateForm({ ...stateForm, category_name: e.target.value });
              }}
              onBlur={() => validate("category_name")}
              error={formError?.category_name.length > 0}
              helperText={formError?.category_name}
            />
            <TextField
              label={<LabelRequired title="追加ボタン名" />}
              value={stateForm.button_name}
              onChange={(e) => {
                setStateForm({ ...stateForm, button_name: e.target.value });
              }}
              onBlur={() => validate("button_name")}
              error={formError?.button_name.length > 0}
              helperText={formError?.button_name}
            />
            <TextField
              label={<LabelRequired title="区切り文字" />}
              value={stateForm.separator ?? ","}
              onChange={(e) => {
                setStateForm({ ...stateForm, separator: e.target.value });
              }}
              onBlur={() => validate("separator")}
              error={formError?.separator.length > 0}
              helperText={formError?.separator}
            />
            <TextField
              label="上限数"
              value={stateForm.maxlen}
              onChange={(e) => {
                setStateForm({ ...stateForm, maxlen: e.target.value });
              }}
              onBlur={() => validate("maxlen", "上限数")}
              error={formError?.maxlen.length > 0}
              helperText={formError?.maxlen}
            />
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      case EXCEL_TEMPLATE_INPUT.ATTACHED_FILE:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            <TextField
              label="上限数"
              value={stateForm.maxlen}
              onChange={(e) => {
                setStateForm({ ...stateForm, maxlen: e.target.value });
              }}
              onBlur={() => validate("maxlen", "上限数")}
              error={formError?.maxlen.length > 0}
              helperText={formError?.maxlen}
            />
            <FormControl error={formError?.maxFileSize.length > 0}>
              <InputLabel>
                <LabelRequired title="ファイル容量制限" />
              </InputLabel>
              <OutlinedInput
                value={stateForm.maxFileSize}
                onChange={(e) => {
                  setStateForm({ ...stateForm, maxFileSize: e.target.value });
                }}
                onBlur={() => validate("maxFileSize")}
                endAdornment={
                  <InputAdornment position="end">MB</InputAdornment>
                }
              />
              <FormHelperText>{formError?.maxFileSize}</FormHelperText>
            </FormControl>
            {RequiredInput}
            {displayInput}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment key={`input-excel-${stateForm.input}`}>
            {NameInput}
            {SelectInputType}
            {FontSizeInput}
            {FontColorInput}
          </React.Fragment>
        );
    }
  }, [stateForm, formError, listMaster]);

  const nameInputOptions = useMemo(() => {
    // 登録済みの項目名から、未設定の項目名を抽出
    const list_name = excelTemplate.map((value) => value.name);
    return listInputName.filter((value) => !list_name.includes(value));
  }, [listInputName, excelTemplate]);

  // 入力設定されたマスタに紐づく追加項目のリスト
  const listSelectedMaster: IListSelectedMaster[] = useMemo(() => {
    let response: any[] = [];
    excelTemplate.forEach((value) => {
      if (
        value.input == EXCEL_TEMPLATE_INPUT.MASTER &&
        value.coordinate != stateForm?.coordinate
      ) {
        // 入力方法がマスタ、かつ、表示中の入力設定以外のとき
        const selected = listMaster.find((v) => v.prefix === value.master);
        if (selected && selected.is_usermade) {
          // ユーザー作成マスタのとき
          const custom_item: any[] = selected.custom_item || [];
          const selected_custom_master = custom_item.map((v, i) => ({
            coordinate:
              EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM + response.length + i, // 1000から連番
            custom_item_name: value.name + " - " + v.name,
            master_coordinate: value.coordinate,
            master_attr: v.barcode ? "barcode_item" : "item" + (i + 1),
          }));
          response = response.concat(selected_custom_master);
        }
      }
    });
    return response;
  }, [stateForm, excelTemplate]);

  const NameInput = useMemo((): React.ReactNode => {
    return (
      stateForm && (
        <Autocomplete
          freeSolo
          disableClearable
          options={nameInputOptions}
          value={stateForm.name ?? null}
          onInputChange={(e, newValue) => {
            setStateForm({ ...stateForm, name: newValue });
          }}
          onBlur={() => validate("name")}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<LabelRequired title="項目名" />}
              multiline
              error={formError?.name.length > 0}
              helperText={formError?.name}
            />
          )}
        />
      )
    );
  }, [stateForm, formError, nameInputOptions]);

  const SelectInputType = useMemo((): React.ReactNode => {
    const getDefaultValue = (input: number) => {
      if (input == EXCEL_TEMPLATE_INPUT.USER_NAME) {
        return "login";
      } else if (input == EXCEL_TEMPLATE_INPUT.USER_SEAL) {
        return "login";
      } else if (input == EXCEL_TEMPLATE_INPUT.NUMBER) {
        return "0";
      } else {
        return "";
      }
    };

    const MultilineList: number[] = [EXCEL_TEMPLATE_INPUT.INPUT_GROUP];

    const list_input_menu: {
      value: any;
      label: string;
      div: TInputMenuDiv[];
    }[] = [
      {
        value: EXCEL_TEMPLATE_INPUT.TEXT,
        label: "テキスト入力",
        div: ["groupInput"],
      },
      {
        value: EXCEL_TEMPLATE_INPUT.MASTER,
        label: "マスタ",
        div: ["groupInput"],
      },
      { value: EXCEL_TEMPLATE_INPUT.DATE, label: "日付", div: ["groupInput"] },
      { value: EXCEL_TEMPLATE_INPUT.IMAGE, label: "画像", div: [] },
      { value: EXCEL_TEMPLATE_INPUT.REPORT_NO, label: "帳票番号", div: [] },
      {
        value: EXCEL_TEMPLATE_INPUT.USER_NAME,
        label: "アカウント名",
        div: ["groupInput"],
      },
      { value: EXCEL_TEMPLATE_INPUT.USER_SEAL, label: "電子印", div: [] },
      { value: EXCEL_TEMPLATE_INPUT.APPROVED_SEAL, label: "承認印", div: [] },
      { value: EXCEL_TEMPLATE_INPUT.APPROVED_DATE, label: "承認日付", div: [] },
      { value: EXCEL_TEMPLATE_INPUT.SIGNATURE, label: "サイン入力", div: [] },
      { value: EXCEL_TEMPLATE_INPUT.DRAW_IMAGE, label: "画像設定", div: [] },
      { value: EXCEL_TEMPLATE_INPUT.CALC, label: "計算", div: ["groupInput"] },
      {
        value: EXCEL_TEMPLATE_INPUT.NUMBER,
        label: "数値入力",
        div: ["groupInput"],
      },
      {
        value: EXCEL_TEMPLATE_INPUT.RADIO,
        label: "ラジオボタン",
        div: ["groupInput"],
      },
      { value: EXCEL_TEMPLATE_INPUT.TIME, label: "時間", div: ["groupInput"] },
      {
        value: EXCEL_TEMPLATE_INPUT.BARCODE,
        label: "バーコード",
        div: ["groupInput"],
      },
      {
        value: EXCEL_TEMPLATE_INPUT.CHECKBOX,
        label: "チェックボックス",
        div: ["groupInput"],
      },
      {
        value: EXCEL_TEMPLATE_INPUT.INPUT_GROUP,
        label: "入力グループ",
        div: [],
      },
      {
        value: EXCEL_TEMPLATE_INPUT.ATTACHED_FILE,
        label: "添付ファイル",
        div: [],
      },
    ];

    return (
      stateForm && (
        <SelectLabel
          label={<LabelRequired title="入力方法" />}
          value={stateForm.input}
          onChange={(e) => {
            if (typeof e.target.value != "string") {
              setStateForm({
                ...stateForm,
                input: e.target.value,
                maxlen: "",
                multiline: MultilineList.includes(e.target.value),
                lineRows: 1,
                default: getDefaultValue(e.target.value),
                master_coordinate:
                  e.target.value >= EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM
                    ? listSelectedMaster[
                        e.target.value - EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM
                      ].master_coordinate
                    : undefined,
                master_attr:
                  e.target.value >= EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM
                    ? listSelectedMaster[
                        e.target.value - EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM
                      ].master_attr
                    : undefined,
                image: undefined,
                isDraw: false,
                calcRound: EXCEL_ROUND[0].value, // 四捨五入
                calcRoundPlace: 0,
                radio: undefined,
                dateFormat: EXCEL_DATE_FORMAT[0].value, // 西暦
                timeFormat: EXCEL_TIME_FORMAT[0].value, // 24時間
                customizeFormat: undefined,
                required: false,
                required_step: undefined,
                display_step: undefined,
                checkbox: undefined,
                group_info: undefined,
                category_name: undefined,
                button_name: undefined,
                maxFileSize: "",
                separator: ",",
              });
            }
          }}
        >
          {list_input_menu
            .filter(
              (item) =>
                inputMenuDiv == "all" || item.div.includes(inputMenuDiv),
            )
            .map((item, i) => (
              <MenuItem value={item.value} key={i}>
                {item.label}
              </MenuItem>
            ))}
          {listSelectedMaster.map((v, i) => (
            <MenuItem value={v.coordinate} key={i}>
              {v.custom_item_name}
            </MenuItem>
          ))}
        </SelectLabel>
      )
    );
  }, [stateForm, formError, listSelectedMaster, inputMenuDiv]);

  const DefaultInput = useMemo(() => {
    switch (stateForm?.input) {
      case EXCEL_TEMPLATE_INPUT.DATE:
      case EXCEL_TEMPLATE_INPUT.TIME:
        return (
          <SelectLabel
            label="初期値"
            onChange={(e) => {
              setStateForm({ ...stateForm, default: e.target.value });
            }}
            value={stateForm.default}
          >
            <MenuItem value="">
              <em>未選択</em>
            </MenuItem>
            <MenuItem value="today">起票日(システム日付)</MenuItem>
          </SelectLabel>
        );
      case EXCEL_TEMPLATE_INPUT.TEXT:
      case EXCEL_TEMPLATE_INPUT.BARCODE:
        return (
          <TextField
            label="初期値"
            value={stateForm.default}
            onChange={(e) => {
              setStateForm({ ...stateForm, default: e.target.value });
            }}
          />
        );
      case EXCEL_TEMPLATE_INPUT.USER_NAME:
        return (
          <FormControl>
            <InputLabel>初期値</InputLabel>
            <CheckboxLabel
              label="ログインアカウント"
              checked={stateForm.default === "login"}
              onChange={(_, checked) => {
                setStateForm({
                  ...stateForm,
                  default: checked ? "login" : "",
                });
              }}
            />
          </FormControl>
        );
      case EXCEL_TEMPLATE_INPUT.USER_SEAL:
        return (
          <SelectLabel
            label={<LabelRequired title="電子印内容" />}
            onChange={(e) => {
              setStateForm({ ...stateForm, default: e.target.value });
            }}
            value={stateForm.default}
          >
            <MenuItem value={"login"}>ログインアカウント</MenuItem>
            {excelTemplate.map((item, i) => {
              return ListSealSelectable.includes(item.input) ? (
                <MenuItem value={item.coordinate} key={i}>
                  {item.name}
                </MenuItem>
              ) : null;
            })}
          </SelectLabel>
        );
      case EXCEL_TEMPLATE_INPUT.CHECKBOX:
        return (
          <SelectLabel
            label="初期値"
            onChange={(e) => {
              setStateForm({ ...stateForm, default: e.target.value });
            }}
            value={stateForm.default}
          >
            <MenuItem value="">
              <em>未選択</em>
            </MenuItem>
            <MenuItem value={"true"}>ON</MenuItem>
          </SelectLabel>
        );
      default:
        return <></>;
    }
  }, [stateForm, formError]);

  const RequiredInput = useMemo(() => {
    return (
      stateForm && (
        <Stack>
          <CheckboxLabel
            label="必須"
            checked={stateForm.required}
            onChange={(_, checked) => {
              setStateForm({
                ...stateForm,
                required: checked,
                required_step: undefined,
              });
            }}
          />
          <SelectLabel
            label="必須承認ステップ"
            value={stateForm.required_step ?? "null"}
            onChange={(e) => {
              setStateForm({
                ...stateForm,
                required_step:
                  e.target.value == "null" ? undefined : Number(e.target.value),
              });
            }}
            disabled={!stateForm.required}
          >
            <MenuItem value={"null"}>すべて</MenuItem>
            {[...Array(10)].map((_, i) => (
              <MenuItem value={i + 1} key={i}>
                承認ステップ{i + 1} 以降
              </MenuItem>
            ))}
          </SelectLabel>
        </Stack>
      )
    );
  }, [stateForm]);

  const FontSizeInput = useMemo(() => {
    return (
      stateForm && (
        <FormControl error={formError?.fontSize.length > 0}>
          <InputLabel>
            <LabelRequired title="フォントサイズ" />
          </InputLabel>
          <OutlinedInput
            value={stateForm.fontSize}
            onChange={(e) => {
              setStateForm({ ...stateForm, fontSize: e.target.value });
            }}
            endAdornment={<InputAdornment position="end">pt</InputAdornment>}
            onBlur={() => validate("fontSize")}
          />
          <FormHelperText>{formError?.fontSize}</FormHelperText>
        </FormControl>
      )
    );
  }, [stateForm, formError]);

  const FontColorInput = useMemo(() => {
    return (
      stateForm && (
        <SelectLabel
          label={<LabelRequired title="フォント色" />}
          onChange={(e) => {
            setStateForm({ ...stateForm, fontColor: e.target.value });
          }}
          inputProps={{
            sx: {
              display: "flex",
              alignItems: "center",
            },
          }}
          value={stateForm.fontColor}
        >
          {EXCEL_TEMPLATE_FONT_COLOR.map((item, i) => (
            <MenuItem value={item.value} key={i}>
              <ListItemIcon sx={{ mr: 1 }}>
                <Circle sx={{ color: item.value ?? "transparent" }} />
              </ListItemIcon>
              {item.text}
            </MenuItem>
          ))}
        </SelectLabel>
      )
    );
  }, [stateForm, formError]);

  const ApprovalStep = useMemo(() => {
    return (
      stateForm && (
        <SelectLabel
          label={<LabelRequired title="承認ステップ" />}
          onChange={(e) => {
            setStateForm({
              ...stateForm,
              approval_step: Number(e.target.value),
            });
          }}
          value={stateForm.approval_step}
        >
          {[...Array(10)].map((_, i) => (
            <MenuItem value={i + 1} key={i}>
              {`承認ステップ${i + 1}`}
            </MenuItem>
          ))}
        </SelectLabel>
      )
    );
  }, [stateForm]);

  const RadioInput = useMemo(() => {
    const handleAddRadio = () => {
      if (!stateForm) return;
      let newRadio = _.cloneDeep(stateForm.radio) ?? [];
      newRadio.push({ label: "", text: "" });
      setStateForm({ ...stateForm, radio: newRadio });
    };

    const handleRemoveRadio = (index: number) => {
      if (!stateForm) return;
      let newRadio = _.cloneDeep(stateForm.radio) ?? [];
      newRadio.splice(index, 1);
      setStateForm({ ...stateForm, radio: newRadio });
    };

    const handleChangeRadioInput = (
      value: TemplateExcelRadio,
      index: number,
    ) => {
      if (!stateForm || !stateForm.radio || stateForm.radio.length < index + 1)
        return;
      let newRadio = _.cloneDeep(stateForm.radio) ?? [];
      newRadio[index] = { ...value };
      setStateForm({ ...stateForm, radio: newRadio });
    };

    return (
      stateForm && (
        <FormControl>
          <InputLabel>選択項目 </InputLabel>
          <Stack>
            {stateForm.radio?.map((item, index) => (
              <CancelBtnBadge
                onClick={handleRemoveRadio}
                sx={{ width: "100%" }}
                componentsProps={{ badge: { style: { top: 15, right: 15 } } }}
                key={index}
              >
                <Box
                  border="3px solid"
                  borderColor={Colors.MAIN_GREEN}
                  borderRadius={1}
                  padding={1}
                  flex={1}
                  display={"flex"}
                  flexDirection="column"
                  position="relative"
                  width={"100%"}
                  sx={{ gap: 1 }}
                >
                  <TextField
                    label="項目名"
                    value={item.label}
                    onChange={(e) => {
                      handleChangeRadioInput(
                        {
                          ...item,
                          label: e.target.value,
                        },
                        index,
                      );
                    }}
                  />
                  <TextField
                    label="表示内容"
                    value={item.text}
                    onChange={(e) => {
                      handleChangeRadioInput(
                        { ...item, text: e.target.value },
                        index,
                      );
                    }}
                  />
                </Box>
              </CancelBtnBadge>
            ))}
            <Button onClick={handleAddRadio}>追加</Button>
          </Stack>
        </FormControl>
      )
    );
  }, [stateForm]);

  const displayInput = useMemo(() => {
    return (
      stateForm && (
        <SelectLabel
          label="表示設定"
          value={stateForm.display_step ?? "null"}
          onChange={(e) => {
            setStateForm({
              ...stateForm,
              display_step:
                e.target.value == "null" ? undefined : Number(e.target.value),
            });
          }}
          helperText={formError.displayStep}
          error={formError.displayStep.length > 0}
        >
          <MenuItem value={"null"}>すべて</MenuItem>
          {[...Array(10)].map((_, i) => (
            <MenuItem value={i + 1} key={i}>
              承認ステップ{i + 1} 以降
            </MenuItem>
          ))}
        </SelectLabel>
      )
    );
  }, [stateForm, formError]);

  const ImagePageHeader = (
    label: React.ReactNode,
    field: keyof TemplateExcelDataInfo,
  ) => {
    return (
      stateForm && (
        <SelectLabel
          label={label}
          onChange={(e) => {
            setStateForm({ ...stateForm, [field]: e.target.value });
          }}
          value={stateForm[field]}
        >
          <MenuItem value={""}>
            <em>未選択</em>
          </MenuItem>
          {excelTemplate.map((item, i) => {
            return ListImagePageSelectable.includes(item.input) ? (
              <MenuItem value={item.coordinate} key={i}>
                {item.name}
              </MenuItem>
            ) : null;
          })}
        </SelectLabel>
      )
    );
  };

  const CustomizeFormat = (disabled: boolean) => {
    return (
      stateForm && (
        <FormControl sx={{ position: "relative" }}>
          <TextField
            label={<LabelRequired title="カスタマイズ形式" />}
            value={stateForm.customizeFormat}
            onChange={(e) => {
              setStateForm({
                ...stateForm,
                customizeFormat: e.target.value,
              });
            }}
            onBlur={() => validate("customizeFormat")}
            error={formError?.customizeFormat.length > 0}
            helperText={formError?.customizeFormat}
            disabled={disabled}
          />
          <IconButton
            sx={{ p: 0, position: "absolute", top: 0, right: 0 }}
            onClick={() => setOpenDateFormatInfo(true)}
          >
            <Info />
          </IconButton>
        </FormControl>
      )
    );
  };

  return (
    <Stack>
      <DateFormatInfoDialog
        open={openDateFormatInfo}
        onClose={() => setOpenDateFormatInfo(false)}
      />
      <GroupInputDialog
        open={openGroupInput}
        stateForm={stateForm?.group_info ?? []}
        activityBaseId={activityBaseId}
        onChange={(value) => {
          if (stateForm) {
            setStateForm({ ...stateForm, group_info: _.cloneDeep(value) });
          }
        }}
        onClose={() => setOpenGroupInput(false)}
      />
      {_renderInput()}
    </Stack>
  );
};
export default forwardRef(CreateTemplateInput);
