import { getAllReportItem, getAllReportItemUpdatedAt } from "@api/itemReport";
import { cloneDeep } from "lodash";
import { STORAGE, getDataStorage, storeData } from "@utils/Storage";
import { getUserInfo } from "shared/utils";

export function* setSettingReportItemData(action: any): any {
  /*
 庫内商品事故報告書のマスタデータ取得
  */
  try {
    const { activity_base_id } = action.payload;
    const resReportItem = yield getNewReportItem(activity_base_id);
    storeData(STORAGE.ALL_REPORT_ITEM, JSON.stringify(resReportItem));
  } catch (error) {
    console.log("function*setSettingReportItemData ~ error", error);
  }
}

export async function getNewReportItem(
  activity_base_id: string,
  master_update_at: any = undefined,
  isStore: boolean = true,
): Promise<any> {
  var storeReportItem = {
    publisher: [],
    occurrenceLocation: [],
    spanMaster: [],
    shipper: [],
    productStatus: [],
    accidentReason: [],
    importDivision: [],
    accidentHandling: [],
  };
  return await storeMasterData(
    activity_base_id,
    STORAGE.ALL_REPORT_ITEM,
    storeReportItem,
    master_update_at,
    isStore,
  );
}

export async function getUserMasterItem(
  activity_base_id: string,
  master_update_at: any = undefined,
  isStore: boolean = true,
): Promise<any> {
  var storeReportItem = {};
  return await storeMasterData(
    activity_base_id,
    STORAGE.ALL_USER_MASTER_ITEM,
    storeReportItem,
    master_update_at,
    isStore,
  );
}

async function storeMasterData(
  activity_base_id: string,
  storage_key: string,
  default_data: any,
  master_update_at: any = undefined,
  isStore: boolean = true,
): Promise<any> {
  if (!master_update_at) {
    // マスタ更新日時取得
    master_update_at = await getAllReportItemUpdatedAt(activity_base_id);
  }

  // キャッシュのマスタデータ取得
  let storeReportItem = await getDataStorage(storage_key);
  if (!storeReportItem || getUserInfo().location_id != activity_base_id) {
    // マスタデータ未取得時、ログイン拠点以外のマスタデータ取得時は初期化する
    storeReportItem = default_data;
  } else {
    storeReportItem = JSON.parse(storeReportItem);
    Object.keys(default_data).forEach((key) => {
      if (!storeReportItem[key]) {
        storeReportItem[key] = [];
      }
    });
  }

  // マスタ再取得対象
  let listGetReportItem: Array<any> = [];

  Object.keys(storeReportItem).forEach((key) => {
    let listStore = cloneDeep(storeReportItem[key]);
    if (listStore && listStore.length > 0) {
      if (Object.keys(master_update_at).includes(key)) {
        if (master_update_at[key] !== "") {
          listStore.sort((a: any, b: any) =>
            a.updated_at > b.updated_at ? -1 : 1,
          );
          if (master_update_at[key] > listStore[0].updated_at) {
            // マスタデータ更新時
            listGetReportItem.push(key.toString());
          }
        }
      }
    } else {
      // マスタデータ未取得時
      listGetReportItem.push(key.toString());
    }
  });
  if (
    storage_key === STORAGE.ALL_USER_MASTER_ITEM &&
    Object.values(storeReportItem).length == 0 &&
    listGetReportItem.length == 0
  ) {
    listGetReportItem.push("MasterType");
  }

  // マスタ再取得
  if (listGetReportItem.length > 0) {
    // クエリ文字数が最大桁数を超えないように分割
    const listGetReportItemChunk: any[][] = [];
    let i = 0,
      j = listGetReportItem.length,
      size = 0;
    while (i < j) {
      size++;
      if (
        listGetReportItem.slice(i, i + size).join("").length > 1500 ||
        i + size >= j
      ) {
        listGetReportItemChunk.push(listGetReportItem.slice(i, i + size));
        i += size;
        size = 0;
      }
    }

    for (const report of listGetReportItemChunk) {
      const resReportItem = await getAllReportItem(activity_base_id, {
        master: JSON.stringify(report),
      });
      for (const value of report) {
        const key = value;
        if (key == "MasterType") {
          Object.keys(resReportItem).forEach((key) => {
            if (key.includes("MASTER_TYPE#")) {
              storeReportItem[key] = resReportItem[key];
            }
          });
        } else {
          storeReportItem[key] = resReportItem[key];
        }
      }
    }
  }

  // マスタデータをキャッシュに保存
  if (isStore) {
    storeData(storage_key, JSON.stringify(storeReportItem));
  }

  return storeReportItem;
}
