import React from "react";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { useDispatch } from "react-redux";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import {
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from "@mui/material";
import {
  Home,
  ManageAccounts,
  Logout as LogoutIcon,
  Save,
  ExpandLess,
  ExpandMore,
  Group,
  Article,
  Search,
  EventAvailable,
  DesignServices,
  Help,
} from "@mui/icons-material";
import { TYPES } from "store/types";
import { getUserInfo } from "@utils/index";
import { USER_ROLES, URL_LOGISTICS } from "@shared-constants";

const logountPath = "/Logout";

interface INavigatorItem {
  title: string;
  icon?: React.ReactNode;
  path: string;
  collapse?: INavigatorItem[];
  disableRole?: string[];
  isDisableReport?: boolean;
  targetBlank?: boolean;
}

interface INavigatorItemProps extends INavigatorItem {
  userRole: string;
  userReport: boolean;
  level: number;
}

export default function Navigator(props: DrawerProps) {
  const { ...other } = props;
  const user_info = getUserInfo();
  // メニュー設定
  const categories: {
    name: string;
    item: INavigatorItem[];
  }[] = [
    {
      name: "",
      item: [
        {
          title: "ダッシュボード",
          icon: <Home />,
          path: "/",
          disableRole: [USER_ROLES.OWNER.value],
        },
        // Phase1 delete
        // {
        //   title: "案件一覧",
        //   icon: <Home />,
        //   path: "/",
        // },
        {
          title: "管理者メニュー",
          icon: <ManageAccounts />,
          path: "/manage",
          disableRole: [USER_ROLES.MEMBER.value, USER_ROLES.EDITOR.value],
        },
        {
          title: "帳票管理",
          icon: <Article />,
          path: "/project",
          disableRole: [
            USER_ROLES.ADMIN.value,
            USER_ROLES.MEMBER.value,
            USER_ROLES.EDITOR.value,
          ],
        },
        {
          title: "帳票検索",
          icon: <Search />,
          path: "/work/search",
          disableRole: [USER_ROLES.OWNER.value],
        },
        //--LOGISTICS
        // {
        //   title: "デバン管理",
        //   icon: <EventAvailable />,
        //   path: "",
        //   collapse: [
        //     {
        //       title: "デバン予定表取込",
        //       path: "/devan/import",
        //     },
        //     {
        //       title: "デバン予定表一覧",
        //       path: "/devan/list",
        //     },
        //     {
        //       title: "デバン受付",
        //       path: "/devan/search-devan",
        //     },
        //   ],
        //   disableRole: [USER_ROLES.OWNER.value],
        // },
        //--
        {
          title: "ユーザー作成帳票一覧",
          icon: <DesignServices />,
          path: "/custom-template/list",
          isDisableReport: true,
        },
        {
          title: "マスタ管理",
          icon: <Save />,
          path: "",
          collapse: [
            //--LOGISTICS
            // {
            //   title: "庫内商品事故報告書項目",
            //   path: "",
            //   collapse: [
            //     {
            //       title: "発生場所・格納場所",
            //       path: "/place",
            //     },
            //     {
            //       title: "発行元",
            //       path: "/publisher",
            //     },
            //     {
            //       title: "商品状態",
            //       path: "/product_status",
            //     },
            //     {
            //       title: "事故発生理由",
            //       path: "/accident_reason",
            //     },
            //     {
            //       title: "事故処理",
            //       path: "/accident_handling",
            //     },
            //     {
            //       title: "荷主",
            //       path: "/shipper",
            //     },
            //     {
            //       title: "スパン",
            //       path: "/span",
            //     },
            //     {
            //       title: "重要度区分",
            //       path: "/import_division",
            //     },
            //     {
            //       title: "事故商品",
            //       path: "/product",
            //     },
            //   ],
            //   disableRole: [USER_ROLES.MEMBER.value],
            // },
            // {
            //   title: "日次フォークリフト点検簿項目",
            //   path: "",
            //   collapse: [
            //     {
            //       title: "燃料・車種名",
            //       path: "/fuel",
            //     },
            //     {
            //       title: "点検箇所・点検項目",
            //       path: "/forklift-item",
            //     },
            //     {
            //       title: "事業場",
            //       path: "/workplace",
            //     },
            //     {
            //       title: "車両",
            //       path: "/vehicles",
            //     },
            //   ],
            //   disableRole: [USER_ROLES.MEMBER.value],
            // },
            //--
            {
              title: "グループ管理",
              path: "",
              collapse: [
                {
                  title: "グループ階層管理",
                  path: "/group/level",
                },
                {
                  title: "グループ管理",
                  path: "/group/info",
                },
                {
                  title: "拠点管理",
                  path: "/group/activitybase",
                },
              ],
              disableRole: [
                USER_ROLES.ADMIN.value,
                USER_ROLES.EDITOR.value,
                USER_ROLES.MEMBER.value,
              ],
            },
            {
              title: "帳票",
              path: "",
              collapse: [
                {
                  title: "帳票種類",
                  path: "/report_type",
                  isDisableReport: true,
                },
                {
                  title: "帳票",
                  path: "/report",
                  isDisableReport: true,
                },
              ],
              disableRole: [
                USER_ROLES.ADMIN.value,
                USER_ROLES.EDITOR.value,
                USER_ROLES.MEMBER.value,
              ],
              isDisableReport: true,
            },
            {
              title: "レッカー受付マスタ",
              path: "/wrecker-master",
              disableRole: [USER_ROLES.OWNER.value, USER_ROLES.MEMBER.value],
            },
            {
              title: "ユーザー作成マスタ",
              path: "/custom-master/list",
              isDisableReport: true,
            },
            {
              title: "CSV取込",
              path: "/master/import-csv",
              isDisableReport: true,
            },
          ],
          disableRole: [USER_ROLES.MEMBER.value],
          isDisableReport: true,
        },
        {
          title: "アカウント管理",
          icon: <Group />,
          path: "",
          collapse: [
            {
              title: "アカウント一覧",
              path: "/account/list",
              disableRole: [USER_ROLES.MEMBER.value, USER_ROLES.EDITOR.value],
            },
            {
              title: "マイアカウント設定",
              path: "/account",
            },
          ],
        },
      ],
    },
    {
      name: "",
      item: [
        {
          title: "サポート",
          icon: <Help />,
          path: "",
          collapse: [
            {
              title: "よくある質問",
              path: URL_LOGISTICS.FAQ,
              targetBlank: true,
            },
            {
              title: "利用規約",
              path: URL_LOGISTICS.TOS,
              targetBlank: true,
            },
            {
              title: "プライバシーポリシー",
              path: URL_LOGISTICS.PRIVACY_POLICY,
              targetBlank: true,
            },
          ],
        },
        {
          title: "ログアウト",
          icon: <LogoutIcon />,
          path: logountPath,
        },
      ],
    },
  ];

  return (
    <Drawer {...other}>
      <List disablePadding>
        <ListItem sx={{ fontSize: 18, pb: 1 }}>メニュー</ListItem>

        {categories.map(({ name, item }, i) => (
          <Box key={i} sx={{ mt: 1 }}>
            {name !== "" && (
              <ListItem sx={{ py: 1 }}>
                <ListItemText>{name}</ListItemText>
              </ListItem>
            )}
            {item.map(
              (
                {
                  title,
                  icon,
                  path,
                  collapse,
                  disableRole = [],
                  isDisableReport,
                },
                n,
              ) => {
                {
                  /* 暫定処理 */
                }
                if (
                  title === "レッカー受付マスタ" &&
                  user_info.PK ===
                    "CONTRACT#e991a245-7377-41be-8e44-0ddfe2055abe"
                ) {
                  return <React.Fragment key={n} />;
                }
                if (isDisableReport) {
                  if (disableRole.length > 0) {
                    if (
                      disableRole.indexOf(user_info.user_role) >= 0 &&
                      !user_info.can_create_user_report
                    ) {
                      return <React.Fragment key={n} />;
                    }
                  } else {
                    if (!user_info.can_create_user_report)
                      return <React.Fragment key={n} />;
                  }
                } else {
                  if (disableRole.indexOf(user_info.user_role) >= 0)
                    return <React.Fragment key={n} />;
                }
                return (
                  <NavigatorItem
                    title={title}
                    icon={icon}
                    path={path}
                    collapse={collapse}
                    userRole={user_info.user_role}
                    userReport={user_info.can_create_user_report}
                    level={0}
                    key={n}
                  />
                );
              },
            )}
            <Divider sx={{ mt: 1 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}

function NavigatorItem(props: INavigatorItemProps) {
  const { title, icon, path, collapse, userRole, userReport, level } = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistoryCustom();
  const dispatch = useDispatch();
  const location_path = history.location.pathname;
  const user_info = getUserInfo();

  // 画面遷移
  const movePage = (path: string, targetBlank?: boolean) => {
    if (path === logountPath) {
      history.block(() => {});
      dispatch({ type: TYPES.LOGOUT });
      return;
    }
    if (targetBlank) {
      window.open(path);
      return;
    }
    history.pushWithRef(path);
  };

  // メニュー開閉チェック
  const checkCurrentOpen = (collapse: INavigatorItem[]): boolean => {
    // パスの一致確認
    let response =
      collapse.filter((item) => item.path === location_path).length > 0;

    if (!response) {
      // 下の階層のパスを確認
      for (let index = 0; index < collapse.length; index++) {
        const sub_collapse = collapse[index];
        if (sub_collapse.collapse) {
          response = checkCurrentOpen(sub_collapse.collapse);
          if (response) break;
        }
      }
    }

    return response;
  };

  // メニュー開閉
  React.useEffect(() => {
    if (collapse) {
      const current_open = checkCurrentOpen(collapse);
      setOpen(current_open);
    }
  }, [collapse, location_path]);

  return (
    <>
      <ListItem
        onClick={() => {
          if (path) {
            movePage(path);
          } else {
            setOpen(!open);
          }
        }}
        disablePadding
        sx={{ py: 1 }}
      >
        <ListItemButton
          selected={path === location_path}
          sx={{
            py: "2px",
            px: 3,
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText sx={styles.itemText}>{title}</ListItemText>
          {path !== "" ? null : open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      {/* 展開メニュー */}
      {path === "" && collapse && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {collapse.map((item: any, i: number) => {
              {
                /* 暫定処理 */
              }
              if (
                item.title === "レッカー受付マスタ" &&
                user_info.PK === "CONTRACT#e991a245-7377-41be-8e44-0ddfe2055abe"
              ) {
                return <React.Fragment key={i} />;
              }
              if (
                item.disableRole &&
                item.disableRole.indexOf(userRole) >= 0 &&
                !item.isDisableReport &&
                !userReport
              )
                return <React.Fragment key={i} />;
              if (
                item.disableRole &&
                item.isDisableReport &&
                !item.isDisableReport
              )
                return <React.Fragment key={i} />;
              if (item.collapse) {
                return (
                  <NavigatorItem
                    title={item.title}
                    icon={
                      <Box sx={{ minWidth: `${16 * (level + 1)}px` }}></Box>
                    }
                    path={item.path}
                    collapse={item.collapse}
                    userRole={userRole}
                    userReport={userReport}
                    level={level + 1}
                    key={i}
                  />
                );
              } else {
                return (
                  <ListItemButton
                    selected={item.path === location_path}
                    onClick={() => {
                      movePage(item.path, item.targetBlank);
                    }}
                    key={i}
                    sx={{ pl: `${16 * level}px` }}
                  >
                    <ListItemText inset sx={styles.itemText}>
                      {item.title}
                    </ListItemText>
                  </ListItemButton>
                );
              }
            })}
          </List>
        </Collapse>
      )}
    </>
  );
}

const styles = {
  itemText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
} as const;
