import React, { useState } from "react";
import {
  Box,
  CardContent,
  CardHeader,
  Card,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Colors } from "@template/style";
import { getDataStorage, STORAGE } from "@utils/Storage";

interface Props {
  max_storage_usage: number;
  memory_used: number;
  percentage_memory?: number;
  max_number_employees: number;
  user_count: number;
  number_of_create_user_report_accounts: number;
  can_create_user_report_user_count: number;
}

const UsageInfo: React.FC<Props> = ({
  max_storage_usage,
  memory_used,
  percentage_memory,
  max_number_employees,
  user_count,
  number_of_create_user_report_accounts,
  can_create_user_report_user_count,
}: Props) => {
  const [open, setOpen] = useState(true);

  const contract_info = getDataStorage(STORAGE.CONTRACT_INFO);
  const contractInfoObject = JSON.parse(contract_info);
  // 割合算出
  if (percentage_memory === undefined) {
    if (max_storage_usage === 0) {
      percentage_memory = 0;
    } else if (memory_used > max_storage_usage) {
      percentage_memory = 100;
    } else {
      percentage_memory = Math.floor((memory_used / max_storage_usage) * 100);
    }
  }
  const percentage_employees =
    max_number_employees === 0
      ? 0
      : user_count > max_number_employees
        ? 100
        : Math.floor((user_count / max_number_employees) * 100);

  const percentage_user_can_create_user_report =
    number_of_create_user_report_accounts === 0
      ? 0
      : can_create_user_report_user_count >
        number_of_create_user_report_accounts
        ? 100
        : Math.floor(
          (can_create_user_report_user_count /
            number_of_create_user_report_accounts) *
          100,
        );

  // 色
  const getProgressColor = (value: number) => {
    if (value < 90) {
      return "primary";
    } else if (value < 100) {
      return "warning";
    } else {
      return "error";
    }
  };

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h6">{`${contractInfoObject?.contract_name}様 ご利用状況`}</Typography>}
        action={
          <IconButton
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={styles.content}>
                <Typography>容量</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="subtitle2">
                  <span className="IgnoreExtractRuleTarget">
                    {memory_used}/{max_storage_usage}(GB) ({percentage_memory}%)
                  </span>
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={percentage_memory}
                sx={styles.progress}
                color={getProgressColor(percentage_memory)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={styles.content}>
                {/* --->> TLOG 
                <Typography>メンバー数</Typography>
                */}
                <Typography>契約アカウント数</Typography>
                {/* <<--- TLOG */}
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                  variant="subtitle2"
                  sx={{
                    color:
                      user_count === max_number_employees && user_count !== 0
                        ? Colors.REQUIRE
                        : undefined,
                  }}
                >
                  <span className="IgnoreExtractRuleTarget">
                    {user_count}
                    {max_number_employees !== 0 && `/${max_number_employees}`}
                  </span>
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={percentage_employees}
                sx={styles.progress}
                color={getProgressColor(percentage_employees)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={styles.content}>
                <Typography>ユーザー作成帳票ライセンス数</Typography>
                {/* <<--- TLOG */}
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                  variant="subtitle2"
                  sx={{
                    color:
                      can_create_user_report_user_count ===
                        number_of_create_user_report_accounts &&
                        can_create_user_report_user_count !== 0
                        ? Colors.REQUIRE
                        : undefined,
                  }}
                >
                  <span className="IgnoreExtractRuleTarget">
                    {can_create_user_report_user_count}
                    {number_of_create_user_report_accounts !== 0 &&
                      `/${number_of_create_user_report_accounts}`}
                  </span>
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={percentage_user_can_create_user_report}
                sx={styles.progress}
                color={getProgressColor(percentage_user_can_create_user_report)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default UsageInfo;

const styles = {
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  progress: { height: "10px", borderRadius: "5px" },
} as const;
