import ExcelJS from "exceljs";

export interface IMasterList {
  id: string;
  name: string;
  group: string;
  is_new?: boolean;
}

export const LIST_GROUP = {
  MASTER: { id: "master", name: "マスタ" },
  USER_MASTER: { id: "user_master", name: "ユーザー作成マスタ" },
  ACCOUNT: { id: "account", name: "アカウント" }
};

export const LIST_MASTER: Array<IMasterList> = [
  // 庫内商品事故報告書
  {
    id: "OCCURRENCE_LOCATION_LIST",
    name: "発生場所・格納場所",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "PUBLISHER_LIST",
    name: "発行元",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "PRODUCT_STATUS_LIST",
    name: "商品状態",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "ACCIDENT_REASON_LIST",
    name: "事故発生理由",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "ACCIDENT_HANDLING_LIST",
    name: "事故処理",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "SHIPPER_LIST",
    name: "荷主",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "SPAN_LIST",
    name: "スパン",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "IMPORT_DIVISION_LIST",
    name: "重要度区分",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "PRODUCT_LIST",
    name: "事故商品",
    group: LIST_GROUP.MASTER.name,
  },
  // 日次フォークリフト点検簿
  {
    id: "FUEL",
    name: "燃料・車種名",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "CHECK_SECTION",
    name: "点検箇所",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "CHECK_ITEM",
    name: "点検項目",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "WORKPLACE_LIST",
    name: "事業場",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "VEHICLES",
    name: "車両",
    group: LIST_GROUP.MASTER.name,
  },
];

// ユーザー作成マスタ
export const NewUserMaster: IMasterList = {
  id: "NEW",
  name: "新規作成",
  group: LIST_GROUP.USER_MASTER.name,
  is_new: true,
};


export const LIST_ACCOUNT: Array<IMasterList> = [
  { id: "ACCOUNT_CREATE", name: "アカウント作成", group: LIST_GROUP.ACCOUNT.name }
]

/***
 * 雛形のヘッダ（1列目は各マスタの名称とする）
 */
export const LIST_EXPORT_CSV: { [id: string]: Array<Partial<ExcelJS.Column>> } =
{
  // 庫内商品事故報告書
  OCCURRENCE_LOCATION_LIST: [
    { header: "発生場所・格納場所名（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  PUBLISHER_LIST: [
    { header: "発行元名（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  PRODUCT_STATUS_LIST: [
    { header: "商品状態名（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  ACCIDENT_REASON_LIST: [
    { header: "事故発生理由名（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  ACCIDENT_HANDLING_LIST: [
    { header: "事故処理名（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  SHIPPER_LIST: [
    { header: "荷主名（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  SPAN_LIST: [
    { header: "スパン名（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  IMPORT_DIVISION_LIST: [
    { header: "重要度区分名（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  PRODUCT_LIST: [
    { header: "事故商品名（必須）" },
    { header: "JANコード（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  // 日次フォークリフト点検簿
  FUEL: [{ header: "燃料・車種名（必須）" }],
  CHECK_SECTION: [{ header: "燃料（必須）" }, { header: "点検箇所（必須）" }],
  CHECK_ITEM: [
    { header: "燃料（必須）" },
    { header: "点検箇所（必須）" },
    { header: "点検項目（必須）" },
  ],
  WORKPLACE_LIST: [{ header: "事業場名（必須）" }],
  VEHICLES: [
    { header: "車両名称（必須）" },
    { header: "車体番号（必須）" },
    { header: "車種（必須）" },
    { header: "燃料（必須）" },
    { header: "使用場所（必須）" },
  ],
  // ユーザー作成マスタ
  [LIST_GROUP.USER_MASTER.id]: [
    { header: "名称（必須）" },
    { header: "手入力有り" },
    { header: "デフォルト値" },
  ],
  ACCOUNT_CREATE: [{ header: "ログインID（必須）" },
  { header: "パスワード(必須)" },
  { header: "名前(必須)" },
  { header: "メールアドレス" },
  { header: "権限(必須)" },
  { header: "グループ名" },
  { header: "役職" },
  { header: "ユーザー作成帳票作成可能" },
  { header: "電子印の表示名" }, //スーパーユーザーの場合はいらない
  { header: "電子印の表示名を縦表示" }, //スーパーユーザーの場合はいらない
  { header: "拠点" },] //スーパーユーザーの場合はいらない
};

export const getRequiredColumn = (id: string) => {
  switch (id) {
    case "OCCURRENCE_LOCATION_LIST": //発生場所・格納場所
    case "PUBLISHER_LIST": //発行元名
    case "PRODUCT_STATUS_LIST": //商品状態
    case "ACCIDENT_REASON_LIST": //事故発生理由
    case "ACCIDENT_HANDLING_LIST": //事故処理名
    case "SHIPPER_LIST": //荷主
    case "SPAN_LIST": //スパン
    case "IMPORT_DIVISION_LIST": //"重要度区分
    case "FUEL": //燃料・車種
    case "WORKPLACE_LIST": // 事業場
    case LIST_GROUP.USER_MASTER.id: //ユーザー作成マスタ
      return [0];
    case "PRODUCT_LIST": //事故商品
    case "CHECK_SECTION": //点検箇所
      return [0, 1];
    case "CHECK_ITEM": //点検項目
      return [0, 1, 2];
    case "VEHICLES": //車両
      return [0, 1, 2, 3, 4];
    case "CREATE_NOT_SUPER_USER":
      return [0, 1, 2, 4, 8, 10];
    case "CREATE_SUPER_USER":
      return [0, 1, 2, 4];
    default:
      return [];
  }
};

//不必要なカラム
export const getNotRequiredColumn = (id: string) => {
  switch (id) {
    case "CREATE_SUPER_USER":
      return [8, 9, 10];
    default:
      return [];
  }
};

export const getDefaultColumnIndex = (id: string) => {
  switch (id) {
    case "OCCURRENCE_LOCATION_LIST": //発生場所・格納場所
    case "PUBLISHER_LIST": //発行元名
    case "PRODUCT_STATUS_LIST": //商品状態
    case "ACCIDENT_REASON_LIST": //事故発生理由
    case "ACCIDENT_HANDLING_LIST": //事故処理名
    case "SHIPPER_LIST": //荷主
    case "SPAN_LIST": //スパン
    case "IMPORT_DIVISION_LIST": //"重要度区分
    case LIST_GROUP.USER_MASTER.id: //ユーザー作成マスタ
      return 2;
    case "PRODUCT_LIST": //事故商品
      return 3;
    case "FUEL": //燃料・車種
    case "CHECK_SECTION": // 点検箇所
    case "CHECK_ITEM": //点検項目
    case "WORKPLACE_LIST": // 事業場
    case "VEHICLES": //車両
      return null;
    default:
      return null;
  }
};

export const getColumnMaxlength = (
  id: string,
): { [index: string]: { maxlen: number } } => {
  switch (id) {
    case "OCCURRENCE_LOCATION_LIST": //発生場所・格納場所
    case "PUBLISHER_LIST": //発行元名
    case "PRODUCT_STATUS_LIST": //商品状態
    case "ACCIDENT_REASON_LIST": //事故発生理由
    case "ACCIDENT_HANDLING_LIST": //事故処理名
    case "SHIPPER_LIST": //荷主
    case "SPAN_LIST": //スパン
    case "IMPORT_DIVISION_LIST": //"重要度区分
    case LIST_GROUP.USER_MASTER.id: //ユーザー作成マスタ
      return { "0": { maxlen: 30 } };
    case "PRODUCT_LIST": //事故商品
      return { "0": { maxlen: 50 }, "1": { maxlen: 13 } };
    case "FUEL": //燃料・車種
      return { "0": { maxlen: 20 } };
    case "CHECK_SECTION": //点検箇所
      return { "1": { maxlen: 7 } };
    case "CHECK_ITEM": //点検項目
      return { "1": { maxlen: 7 }, "2": { maxlen: 18 } };
    case "WORKPLACE_LIST": // 事業場
      return { "0": { maxlen: 20 } };
    case "VEHICLES": //車両
      return { "0": { maxlen: 50 }, "1": { maxlen: 20 }, "2": { maxlen: 20 } };
    case "ACCOUNT_CREATE":
      return {
        "0": { maxlen: 30 },
        "1": { maxlen: 30 },
        "2": { maxlen: 30 },
        "3": { maxlen: 256 },
        "5": { maxlen: 30 },
        "6": { maxlen: 30 },
        "8": { maxlen: 30 },
        "10": { maxlen: 20 },
      };
    default:
      return {};
  }
};

export const getColumnMinlength = (
  id: string,
): { [index: string]: { minlen: number } } => {
  switch (id) {
    case "ACCOUNT_CREATE":
      return {
        "0": { minlen: 6 },
        "1": { minlen: 6 },
      }
    default:
      return {};
  }
};