import { useMemo } from "react";
import { screenIdSupport, TEMPLATE_EDIT_TYPE } from "@shared-constants";
import { IWorkSearchForm } from "../SearchDialog";
import ExcelTemplateDetail from "./ExcelTemplateDetail";

interface ISearchDialogDetail {
  screenId: string;
  stateForm: IWorkSearchForm;
  templateInfo: any;
  setStateForm: Function;
  setFormError: Function;
}

const SearchDialogDetail: React.VFC<ISearchDialogDetail> = ({
  screenId: screen_id,
  stateForm,
  templateInfo,
  setStateForm,
  setFormError,
}: ISearchDialogDetail) => {
  const screenId = useMemo(() => screen_id, [screen_id]);

  const _render = () => {
    switch (screenIdSupport[screenId]) {
      case TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT:
        return (
          <ExcelTemplateDetail
            templateInfo={templateInfo}
            stateForm={stateForm}
            onChange={setStateForm}
            setFormError={setFormError}
          />
        );
      default:
        return null;
    }
  };

  return <>{_render()}</>;
};

export default SearchDialogDetail;
