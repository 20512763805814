import React from "react";
import { Box, Tooltip } from "@mui/material";
import { CreateExcelTemplate } from "services/models";
import "./CreateTemplatePreview.css";

interface IProps {
  data: Array<CreateExcelTemplate>;
  tableRowsHeight: Array<string>;
  coordinate: string;
  handleClick: (coordinate: string, value: string) => void;
}

let tableWidth = 0;
let tableHeight = 0;

const CreateTemplatePreview: React.FC<IProps> = ({
  data,
  tableRowsHeight,
  coordinate,
  handleClick,
}) => {
  const unescapeHTML = (html: string) => {
    var el = document.createElement("textarea");
    el.innerHTML = html;
    return el.textContent;
  };

  return (
    <Box
      component={"div"}
      style={{ border: "1px solid", display: "inline-flex" }}
    >
      <table
        id="table-template-create"
        cellPadding={0}
        cellSpacing={0}
        style={{
          borderCollapse: "collapse",
          tableLayout: "fixed",
          border: 0,
          width: tableWidth + "px",
          height: tableHeight + "px",
          display: "block",
        }}
      >
        <tbody>
          {data &&
            data.map((item, i) => {
              let keys = Object.keys(item);
              let key = keys && keys.length > 0 ? keys[0] : "";
              if (i == 0) {
                // 初期化
                tableHeight = 0;
                tableWidth = 0;
              }
              if (tableWidth == 0) {
                let w = 0;
                item[key].forEach((cell) => {
                  w = w + Number(cell.width.replace("px", ""));
                });
                tableWidth = w;
              }
              if (item[key] && item[key].length > 0)
                tableHeight += Number(item[key][0].height.replace("px", ""));

              return (
                <tr key={i} style={{ height: tableRowsHeight[i] + "px" }}>
                  {item[key].map((cell, n) => (
                    <Tooltip
                      title={cell.coordinate}
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -8],
                            },
                          },
                        ],
                      }}
                      key={n}
                    >
                      <td
                        style={{
                          width: cell.width,
                          height: cell.height,
                          textAlign: cell.textAlign,
                          verticalAlign: cell.verticalAlign,
                          borderTop: cell.border.top
                            ? cell.border.top.style
                            : undefined,
                          borderTopWidth: cell.border.top
                            ? cell.border.top.width
                            : undefined,
                          borderTopColor: cell.border.top
                            ? "#" + cell.border.top.color
                            : undefined,
                          borderLeft: cell.border.left
                            ? cell.border.left.style
                            : undefined,
                          borderLeftWidth: cell.border.left
                            ? cell.border.left.width
                            : undefined,
                          borderLeftColor: cell.border.left
                            ? "#" + cell.border.left.color
                            : undefined,
                          borderRight: cell.border.right
                            ? cell.border.right.style
                            : undefined,
                          borderRightWidth: cell.border.right
                            ? cell.border.right.width
                            : undefined,
                          borderRightColor: cell.border.right
                            ? "#" + cell.border.right.color
                            : undefined,
                          borderBottom: cell.border.bottom
                            ? cell.border.bottom.style
                            : undefined,
                          borderBottomWidth: cell.border.bottom
                            ? cell.border.bottom.width
                            : undefined,
                          borderBottomColor: cell.border.bottom
                            ? "#" + cell.border.bottom.color
                            : undefined,
                          backgroundColor:
                            coordinate === cell.coordinate
                              ? "#7fd5d1"
                              : "#" + cell.bg_color,
                        }}
                        colSpan={cell.colSpan}
                        rowSpan={cell.rowSpan}
                        onClick={() => handleClick(cell.coordinate, cell.value)}
                      >
                        <span
                          style={{
                            color: "#" + cell.font.color,
                            fontSize: cell.font.size,
                            fontFamily: cell.font.fontFamily,
                            fontWeight: cell.font.weight,
                            fontStyle: cell.font.style,
                            textDecoration: cell.font.underline ?? undefined,
                          }}
                        >
                          {cell.value.split(/<br>|\n/g).map((text, i) => (
                            <div key={i}>{unescapeHTML(text)}</div>
                          ))}
                        </span>
                      </td>
                    </Tooltip>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </Box>
  );
};
export default CreateTemplatePreview;
