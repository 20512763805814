import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { TemplateExcelDataInfo } from "services/models";
import CreateTemplateNavi from "./CreateTemplateNavi";
import { initialForm } from "./index";
import CreateTemplateInput from "./CreateTemplateInput";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";

interface IDateFormatInfoDialog {
  open: boolean;
  stateForm: Array<TemplateExcelDataInfo>;
  activityBaseId: string;
  onChange: (value: Array<TemplateExcelDataInfo>) => void;
  onClose: () => void;
}

const GroupInputDialog = ({
  open,
  stateForm,
  activityBaseId,
  onChange,
  onClose,
}: IDateFormatInfoDialog) => {
  const [originData, setOriginData] = useState<Array<TemplateExcelDataInfo>>(
    [],
  );
  const [coordinate, setCoordinate] = useState("");
  const [openItemNavi, setOpenItemNavi] = useState(true);
  const [inputInfo, setInputInfo] = useState<TemplateExcelDataInfo>();
  const [isUpdate, setIsUpdate] = useState(false);
  const refInput = useRef<any>();

  useEffect(() => {
    if (open) {
      setOpenItemNavi(true);
      if (stateForm.length > 0) {
        setOriginData([...stateForm]);
        setCoordinate(stateForm[0].coordinate);
        setInputInfo({ ...stateForm[0] });
        setIsUpdate(true);
      } else {
        setClear();
      }
    }
  }, [open]);

  const setClear = () => {
    setOriginData([]);
    setCoordinate("");
    setInputInfo(undefined);
    setIsUpdate(false);
  };

  const hasUpdateData = () => {
    return refInput.current && refInput.current.hasUpdateData();
  };

  // 更新確認 ------------------------------------------------
  const confirmUpdate = (afterDialogClose?: Function) => {
    ModalController.show({
      message: messages.COMMON.MSG_CONFIRM_GO_BACK,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton1: () => {
        if (typeof afterDialogClose == "function") afterDialogClose();
      },
      handlePressButton2: () => {
        const res = updateStateForm();
        if (res && typeof afterDialogClose == "function") afterDialogClose();
      },
      button1: { title: messages.COMMON.BUTTON.CANCEL },
    });
  };

  const handleSelectNavi = (select_coordinate: string) => {
    if (select_coordinate === coordinate) return;
    if (hasUpdateData()) {
      // 保存確認
      confirmUpdate(() => changeInputInfo(select_coordinate));
      return;
    }
    changeInputInfo(select_coordinate);
  };

  const handleDelete = (select_coordinate: string) => {
    let newStateForm = [
      ...stateForm.filter((item) => item.coordinate !== select_coordinate),
    ];
    // 入力内容の選択を変更
    if (inputInfo?.coordinate === select_coordinate) {
      setInputInfo(newStateForm.length > 0 ? newStateForm[0] : undefined);
      setCoordinate(newStateForm.length > 0 ? newStateForm[0].coordinate : "");
    }
    onChange(newStateForm);
  };

  const changeInputInfo = (select_coordinate: string, name?: string) => {
    let index = stateForm.findIndex(
      (item) => item.coordinate === select_coordinate,
    );
    if (index < 0) {
      setInputInfo({
        ...initialForm,
        coordinate: select_coordinate,
        name: name ?? select_coordinate,
      });
      setIsUpdate(false);
    } else {
      setInputInfo({ ...stateForm[index] });
      setIsUpdate(true);
    }

    setCoordinate(select_coordinate);
  };

  const handleAddItem = () => {
    if (!inputInfo) {
      return;
    }
    const res = updateStateForm();
    if (res) {
      setInputInfo({ ...refInput.current.stateForm });
      setIsUpdate(true);
    }
  };

  const updateStateForm = (
    update_coordinate?: string,
    newStateForm?: TemplateExcelDataInfo[],
  ) => {
    if (!refInput.current || !refInput.current.stateForm) return false;

    // 入力チェック
    if (!refInput.current.validationAllCheck()) {
      ModalController.hide();
      setTimeout(() => {
        ModalController.show({
          message: "入力内容にエラーがあります",
          visibleButton2: true,
        });
      }, 100);
      return false;
    }

    // 入力内容を更新
    if (!update_coordinate) update_coordinate = coordinate;
    if (!newStateForm) newStateForm = [...stateForm];
    let index = stateForm.findIndex(
      (item) => item.coordinate === update_coordinate,
    );
    if (index < 0) {
      // 追加
      newStateForm.push(refInput.current.stateForm);
    } else {
      // 置換
      newStateForm.splice(index, 1, refInput.current.stateForm);
    }
    onChange(newStateForm);
    return newStateForm;
  };

  const handleCancel = () => {
    onChange(originData);
    onClose();
  };

  const handleOK = () => {
    if (hasUpdateData()) {
      // 保存確認
      confirmUpdate(() => {
        setInputInfo({ ...refInput.current.stateForm });
        setIsUpdate(true);
        onClose();
      });
      return;
    }
    onClose();
  };

  return (
    <Dialog
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": { width: "60%", height: "100%" },
        zIndex: 1300,
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>入力項目設定</DialogTitle>
      <DialogContent>
        <Stack direction={{ xs: "column", md: "row" }} pt={0.5} height={"100%"}>
          <Card
            sx={{
              height: "100%",
              width: openItemNavi ? 250 : 40,
              minWidth: openItemNavi ? 250 : 40,
            }}
          >
            <CardContent sx={{ height: "calc(100% - 60px)" }}>
              <CreateTemplateNavi
                open={openItemNavi}
                handleMenu={() => setOpenItemNavi(!openItemNavi)}
                stateForm={stateForm}
                coordinate={coordinate}
                handleSelect={handleSelectNavi}
                handleDelete={handleDelete}
                setStateForm={onChange}
                disableAddTab
                handleAddInput={(coordinate) => changeInputInfo(coordinate, "")}
              />
            </CardContent>
          </Card>
          <Card
            sx={{
              height: "100%",
              width: 300,
              minWidth: 300,
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
              <CreateTemplateInput
                data={inputInfo}
                excelTemplate={stateForm}
                activityBaseId={activityBaseId}
                inputMenuDiv="groupInput"
                ref={refInput}
              />
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                variant="text"
                onClick={handleAddItem}
                disabled={!inputInfo}
              >
                {isUpdate ? "変更" : "追加"}
              </Button>
            </CardActions>
          </Card>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleCancel} variant="outlined">
          キャンセル
        </Button>
        <Button onClick={handleOK}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupInputDialog;
