import dayjs from "dayjs";
import _ from "lodash";
import { TemplateExcelDataInfo } from "services/models";
import { createObjectURL, getBase64Image, getFileByUrl } from "..";
import {
  CUSTOM_FORMAT,
  EXCEL_ROUND,
  EXCEL_TEMPLATE_INPUT,
} from "@shared-constants";
import { createHtmlForMultipleText } from "./";
import formatDateToString, { convertJPEraDate } from "@utils/DateFormat";
import { getParamsHelp, prefixApi } from "@api/Connector";

export interface StateFormType {
  [key: string]: any;
  image_array?: Array<Type.ImageInfoType>;
  custom_image?: Array<Type.ImageInfoType>;
}

export const CommonKey = ["image_array", "custom_image", "risky_id"];

export interface ImageInfoTypePreview extends Type.ImageInfoType {
  base64?: string;
}

export interface IMasterValue {
  id: string | undefined | null;
  name: string;
  is_manual_input?: boolean;
}

export interface IRadioValue {
  index: number;
  value: string;
}

export interface ICheckboxValue {
  checked: boolean;
  value: string;
}

const DOMAIN = process.env.REACT_APP_API_URL;

export const getInfoFromTemplate = (htmlString: string) => {
  let imageContentExample: string = "";
  let pageImageExample: string = "";
  let mainPageExample: string = "";
  const imageContentExampleArray = htmlString.split("image_content_example");
  if (imageContentExampleArray.length >= 3) {
    imageContentExample = imageContentExampleArray[1];
  }
  const pageImageExampleArray = htmlString.split("page_image_example");
  if (pageImageExampleArray.length >= 3) {
    pageImageExample = pageImageExampleArray[1];
  }
  const mainPageExampleArray = htmlString.split("<!-- main -->");
  if (mainPageExampleArray.length >= 3) {
    mainPageExample = mainPageExampleArray[1];
  }
  return { imageContentExample, pageImageExample, mainPageExample };
};

export const getInfoSizeTemplate = (htmlString: string) => {
  let width: string = "";
  let height: string = "";
  let zoom: string = "";
  const tableWidthArray = htmlString.split("table_width");
  if (tableWidthArray.length >= 3) {
    width = tableWidthArray[1].trim();
  }
  const tableHeightArray = htmlString.split("table_height");
  if (tableHeightArray.length >= 3) {
    height = tableHeightArray[1].trim();
  }
  const zoomArray = htmlString.split("table_zoom");
  if (zoomArray.length >= 3) {
    zoom = zoomArray[1].trim();
  }
  return { width, height, zoom };
};

export const getPageImagePreviewHtml = (htmlString: string) => {
  let pageImageHtml: string = "";
  const { imageContentExample, pageImageExample } =
    getInfoFromTemplate(htmlString);
  const pageMainArray = htmlString.split("<!-- main -->");
  if (pageMainArray.length >= 3) {
    pageImageHtml =
      pageMainArray[0] + pageImageExample + pageMainArray.slice(-1)[0];
  }
  // 置換
  [...Array(6)].map((_, i) => {
    let no = i + 1;
    pageImageHtml = pageImageHtml.replaceAll(`{head_title_${no}}`, `項目${no}`);
    pageImageHtml = pageImageHtml.replaceAll(`{head_value_${no}}`, "");
  });
  pageImageHtml = pageImageHtml.replaceAll("{image_title}", "画像タイトル");
  pageImageHtml = pageImageHtml.replaceAll("{image_waiting}", "");
  return pageImageHtml;
};

export const makeNewHtml = async (
  newHtml: string,
  data: StateFormType,
  input_setting: Array<TemplateExcelDataInfo>,
  page?: number,
) => {
  const LIST_IMAGE_INPUT: Array<
    (typeof EXCEL_TEMPLATE_INPUT)[keyof typeof EXCEL_TEMPLATE_INPUT]
  > = [
    EXCEL_TEMPLATE_INPUT.IMAGE,
    EXCEL_TEMPLATE_INPUT.SIGNATURE,
    EXCEL_TEMPLATE_INPUT.DRAW_IMAGE,
    EXCEL_TEMPLATE_INPUT.USER_SEAL,
    EXCEL_TEMPLATE_INPUT.APPROVED_SEAL,
  ];
  for (let index = 0; index < input_setting.length; index++) {
    const item = input_setting[index];

    // set style
    let style = `font-size: ${item.fontSize}pt; color: ${item.fontColor};`;
    if (item.multiline) {
      style += " white-space: initial; word-break: break-all;";
    }
    if (LIST_IMAGE_INPUT.includes(item.input)) {
      style += " display: none;";
    } else {
      style += " display: inline-block;";
    }
    newHtml = newHtml.replace(`<!-- ${item.coordinate}-style -->`, style);

    // set value
    if (
      item.input == EXCEL_TEMPLATE_INPUT.IMAGE ||
      item.input == EXCEL_TEMPLATE_INPUT.SIGNATURE ||
      item.input == EXCEL_TEMPLATE_INPUT.DRAW_IMAGE
    ) {
      const coordinate =
        page !== undefined
          ? getPageKey(item.coordinate, page)
          : item.coordinate;
      newHtml = newHtml.replace(
        `<!-- ${item.coordinate}-other -->`,
        await getHtmlImageValue(
          data[coordinate]?.uri_jpg ?? data[coordinate]?.uri,
        ),
      );
    } else {
      let text = getHTMLTextValue(data, item, input_setting, page);
      text = item.multiline ? createHtmlForMultipleText(text) : text;
      if (text) {
        newHtml = newHtml.replace(`<!-- ${item.coordinate} -->`, text);
      }
    }
  }
  return newHtml;
};

const getHtmlImageValue = async (value: any) => {
  if (value) {
    const base64 = await getBase64Image(value);
    return `<div class="image-div" style="background-image: url('${base64}');" alt=""></div>`;
  } else {
    return "";
  }
};

export const getHTMLTextValue = (
  value: StateFormType,
  setting?: TemplateExcelDataInfo,
  input_setting?: Array<TemplateExcelDataInfo>,
  page?: number,
) => {
  if (!setting) return "";

  const coordinate =
    page !== undefined
      ? getPageKey(setting.coordinate, page)
      : setting.coordinate;
  const input = setting.input;

  let text = value[coordinate] ?? "";
  if (input === EXCEL_TEMPLATE_INPUT.MASTER) {
    text = text?.name;
  } else if (input == EXCEL_TEMPLATE_INPUT.DATE) {
    const date = new Date(text);
    if (Number.isNaN(date.getTime())) {
      text = "";
    } else if (setting.dateFormat == "JP") {
      // 和暦
      text = convertJPEraDate(date);
    } else if (setting.dateFormat == CUSTOM_FORMAT) {
      // カスタマイズ形式
      text = dayjs(date).format(setting.customizeFormat);
    }
  } else if (input === EXCEL_TEMPLATE_INPUT.TIME) {
    if (text.length > 0) {
      const time = formatDateToString(new Date(), "YMD") + "T" + text;
      if (setting.timeFormat == "HHmm") {
        // 24時間
        text = formatDateToString(time, "HHmm");
      } else if (setting.timeFormat == CUSTOM_FORMAT) {
        // カスタマイズ形式
        text = dayjs(time).format(setting.customizeFormat);
      }
    }
  } else if (input == EXCEL_TEMPLATE_INPUT.REPORT_NO) {
    text = value["risky_id"];
  } else if (input == EXCEL_TEMPLATE_INPUT.USER_NAME) {
    text = text?.name;
  } else if (input == EXCEL_TEMPLATE_INPUT.USER_SEAL) {
    text = "";
  } else if (input == EXCEL_TEMPLATE_INPUT.CALC) {
    text = getExcelTemplateCalcText(value, setting, input_setting, page);
  } else if (input === EXCEL_TEMPLATE_INPUT.RADIO) {
    text = text?.value;
  } else if (input === EXCEL_TEMPLATE_INPUT.CHECKBOX) {
    text = text?.value;
  } else if (input === EXCEL_TEMPLATE_INPUT.ATTACHED_FILE) {
    const file_count = text ? text.length : 0;
    text = `添付ファイル（${file_count}）`;
    if (file_count > 0) {
      const url_params = `${getParamsHelp({ coordinate })}&SK={param_work_id}`;
      const url = `${DOMAIN}/${prefixApi}/get-compressed-attached-file?${url_params}`;
      text = `<a href="${url}">${text}</a>`;
    }
  }
  return text;
};

const getExcelSettingByCoordinate = (
  excelTemplate: Array<TemplateExcelDataInfo>,
  coordinate: string | undefined,
) => {
  return excelTemplate.find((item) => item.coordinate == coordinate);
};

export const getImageArray = async (data: Array<any>) => {
  if (!data) return data;
  let image_array: Array<any> = [];
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    const uri = item?.uri_jpg ?? item?.uri;
    if ((!item.base64 || item.base64.length === 0) && uri && uri.length > 0) {
      item.base64 = await getBase64Image(uri);
    }
    image_array.push(item);
  }
  return image_array;
};

export const insertReportLocal = async (
  newHtml: string,
  data: StateFormType,
  input_setting: Array<TemplateExcelDataInfo>,
  imageContentExample: string,
  pageImageExample: string,
  mainPageExample: string,
  image_array: Array<ImageInfoTypePreview>,
  mergeTemplate = false,
) => {
  // 追加ページ
  if (data.page && data.page > 1) {
    for (let page = 2; page < data.page + 1; page++) {
      let mainPageExampleClone = mainPageExample;
      mainPageExampleClone = await makeNewHtml(
        mainPageExample,
        data,
        input_setting,
        page,
      );
      newHtml = newHtml.replace(
        "<!-- {add_more_area} -->",
        `
          <div class="div-page-break"></div>
          ${mainPageExampleClone}
          <!-- main -->
          <!-- {add_more_area} -->
        `,
      );
    }
  }

  // 添付画像ページ
  if (image_array?.length === 0) {
    return newHtml;
  } else {
    const image_array_chunk = _.chunk(image_array, 6);
    const setting = input_setting.find(
      (item) => item.input == EXCEL_TEMPLATE_INPUT.IMAGE_PAGE,
    );
    if (!setting) {
      return newHtml;
    }

    for (const imageArray of image_array_chunk) {
      let pageImageExampleClone = pageImageExample;

      const h1 = getExcelSettingByCoordinate(input_setting, setting.headImage1);
      const h2 = getExcelSettingByCoordinate(input_setting, setting.headImage2);
      const h3 = getExcelSettingByCoordinate(input_setting, setting.headImage3);
      const h4 = getExcelSettingByCoordinate(input_setting, setting.headImage4);
      const h5 = getExcelSettingByCoordinate(input_setting, setting.headImage5);
      const h6 = getExcelSettingByCoordinate(input_setting, setting.headImage6);
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_title_1}",
        h1?.name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_value_1}",
        getHTMLTextValue(data, h1, input_setting),
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_title_2}",
        h2?.name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_value_2}",
        getHTMLTextValue(data, h2, input_setting),
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_title_3}",
        h3?.name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_value_3}",
        getHTMLTextValue(data, h3, input_setting),
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_title_4}",
        h4?.name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_value_4}",
        getHTMLTextValue(data, h4, input_setting),
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_title_5}",
        h5?.name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_value_5}",
        getHTMLTextValue(data, h5, input_setting),
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_title_6}",
        h6?.name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{head_value_6}",
        getHTMLTextValue(data, h6, input_setting),
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{image_title}",
        setting.titleImage ?? "",
      );

      imageArray.map((item: ImageInfoTypePreview) => {
        if (item?.uri) {
          let imageContentExampleClone = imageContentExample;
          imageContentExampleClone = imageContentExampleClone.replace(
            "{image_url}",
            `'${item?.base64 ? item?.base64 : item?.uri_jpg ?? item?.uri}'`,
          );
          pageImageExampleClone = pageImageExampleClone.replace(
            "{image_waiting}",
            imageContentExampleClone,
          );
        } else {
          pageImageExampleClone = pageImageExampleClone.replace(
            "{image_waiting}",
            "",
          );
        }
      });

      pageImageExampleClone = pageImageExampleClone.replaceAll(
        "{image_waiting}",
        "",
      );

      newHtml = newHtml.replace(
        "<!-- {add_more_area} -->",
        `
          ${pageImageExampleClone}
          <!-- {add_more_area} -->
        `,
      );
    }

    return newHtml;
  }
};

const mergeHtmlString = async (
  uri: string,
  stateForm: StateFormType,
  data_info: any,
) => {
  const f = await getFileByUrl(uri, "template.html", "text/html");
  let html_string = await f.text();
  const res = getInfoFromTemplate(html_string);
  const { imageContentExample, pageImageExample, mainPageExample } = res;
  html_string = await makeNewHtml(html_string, stateForm, data_info);
  html_string = await insertReportLocal(
    html_string,
    stateForm,
    data_info,
    imageContentExample,
    pageImageExample,
    mainPageExample,
    stateForm?.image_array || [],
    true,
  );
  return html_string;
};

export const mergeTemplateExcel = async (
  templateUri: string,
  data: StateFormType,
  template: any,
) => {
  try {
    let html_string = await mergeHtmlString(
      templateUri,
      data,
      template?.data_info,
    );
    if (template?.extra_template) {
      // 追加テンプレートを結合
      for (let index = 0; index < template.extra_template.length; index++) {
        const extra_template = template.extra_template[index];
        let extra_html_string = await mergeHtmlString(
          extra_template.template_path,
          getStateFormByTemplate(index + 1, data),
          extra_template.data_info,
        );
        html_string += `
        <!-- extra-html -->
        ${extra_html_string}
        `;
      }
    }
    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(html_string),
    );
    const html_blob = new Blob([html_string], { type: "text/html" });
    templateUri = createObjectURL(html_blob);
  } catch (err) {
    console.log(err);
    return null;
  }
  return templateUri;
};

export const getExcelTemplateCalcText = (
  value: StateFormType,
  setting: TemplateExcelDataInfo,
  input_setting?: Array<TemplateExcelDataInfo>,
  page?: number,
) => {
  let num = calcExcelTemplateValue(value, setting, input_setting, page);
  if (num !== undefined) {
    let place = 10 ** (setting.calcRoundPlace || 0);
    switch (setting.calcRound) {
      case EXCEL_ROUND[0].value:
        // round
        num = Math.round(num * place) / place;
        break;
      case EXCEL_ROUND[1].value:
        // ceil
        num = Math.ceil(num * place) / place;
        break;
      case EXCEL_ROUND[2].value:
        // floor
        num = Math.floor(num * place) / place;
        break;
    }
    return num.toString();
  } else {
    return "";
  }
};

export const calcExcelTemplateValue = (
  value: StateFormType,
  setting: TemplateExcelDataInfo,
  input_setting?: Array<TemplateExcelDataInfo>,
  page?: number,
): number | undefined => {
  try {
    // 計算式の座標を入力値に置換
    let calc_text = setting.calc || "";
    calc_text.split(/[()+*/-]/).forEach((v) => {
      let key = _.toUpper(v.trim());
      if (page !== undefined) key = getPageKey(key, page);
      if (value[key]) {
        calc_text = calc_text.replace(v, value[key]);
      } else if (input_setting) {
        const key_setting = getExcelSettingByCoordinate(input_setting, key);
        if (key_setting && key_setting.input === EXCEL_TEMPLATE_INPUT.CALC) {
          const replace_value = calcExcelTemplateValue(
            value,
            key_setting,
            input_setting,
          );
          if (replace_value !== undefined) {
            calc_text = calc_text.replace(v, replace_value.toString());
          }
        }
      }
    });
    // 式を計算
    let num: number = Function("return " + calc_text)();
    return num === Infinity ? undefined : num;
  } catch (e) {
    return undefined;
  }
};

export const getInputGroupKey = (
  coordinate: string,
  child_coordinate: string,
  index: number,
) => {
  return `${coordinate}#${index.toString()}#${child_coordinate}`;
};

/**
 * ページ別のcoordinateを取得
 * @returns PAGE#[pageno]|[coordinate]
 */
export const getPageKey = (coordinate: string, pageno: number) => {
  return CommonKey.includes(coordinate) || pageno <= 1
    ? coordinate
    : `PAGE#${pageno.toString()}|${coordinate}`;
};

/**
 * テンプレート別のcoordinateを取得
 * @returns TEMPLATE#[templateNo]|[coordinate]
 */
export const getTemplateKey = (coordinate: string, templateNo: number) => {
  return CommonKey.includes(coordinate) || templateNo === 0
    ? coordinate
    : `TEMPLATE#${templateNo}|${coordinate}`;
};

/**
 * 入力データ(stateForm)からテンプレート別の値を抽出
 */
export const getStateFormByTemplate = (
  template_no: number,
  data: StateFormType,
) => {
  const template_key = `TEMPLATE#${template_no}|`;
  const state_values = Object.keys(data)
    .filter(
      (key) =>
        key.startsWith(template_key) ||
        CommonKey.includes(key) ||
        (template_no === 0 && !key.includes("TEMPLATE#")),
    )
    .map((key) => ({ [key.replace(template_key, "")]: data[key] }));
  return Object.assign({}, ...state_values);
};
