import React, { useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import AccordionSection from "components/molecules/AccordionSection";
import LabelRequired from "components/atoms/LabelRequired";
import { TemplateExcelDataInfo } from "services/models";
import { labeltextBreak } from "@utils/index";
import { getInputGroupKey } from "@utils/template/excelTemplate";

interface IInputGroupField {
  setting: TemplateExcelDataInfo;
  listIndex: Array<number>;
  renderItem: (setting: TemplateExcelDataInfo, index: number) => any;
  onAdd: (setting: TemplateExcelDataInfo, index: number) => void;
  onRemove: (setting: TemplateExcelDataInfo, index: number) => void;
}

const InputGroupField = ({
  setting,
  listIndex,
  renderItem,
  onAdd,
  onRemove,
}: IInputGroupField) => {
  const maxlen_group = useMemo(() => Number(setting.maxlen || "0"), [setting]);

  return (
    <FormControl>
      <InputLabel>
        {setting.required ? (
          <LabelRequired title={labeltextBreak(setting.name)} />
        ) : (
          labeltextBreak(setting.name)
        )}
      </InputLabel>
      <Stack>
        {listIndex.map((n) => (
          <Box key={n}>
            <Box sx={{ textAlign: "end" }}>
              <IconButton
                sx={{ p: 0, pb: 0.5 }}
                onClick={() => onRemove(setting, n)}
              >
                <Cancel />
              </IconButton>
            </Box>
            <AccordionSection
              title={setting.category_name}
              defaultExpanded={true}
            >
              <Stack>
                {setting.group_info?.map((item, index) => {
                  const childSetting = cloneDeep(item);
                  childSetting.coordinate = getInputGroupKey(
                    setting.coordinate,
                    childSetting.coordinate,
                    n,
                  );
                  return renderItem(childSetting, index);
                })}
              </Stack>
            </AccordionSection>
          </Box>
        ))}
        <Button
          onClick={() => {
            if (maxlen_group == 0 || listIndex.length < maxlen_group) {
              const maxIndex = (listIndex[listIndex.length - 1] ?? 0) + 1;
              onAdd(setting, maxIndex);
            }
          }}
          disabled={maxlen_group > 0 && listIndex.length >= maxlen_group}
        >
          {setting.button_name}
        </Button>
      </Stack>
    </FormControl>
  );
};

export default InputGroupField;
